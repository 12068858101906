<template>
  <span v-if="$getDebug()">
    props.modelValue:{{ props.modelValue }}<br />
    modelValue:{{ modelValue }}<br />
    intValue:{{ internalValue }}<br />
    strinValue:{{ localCHDate }}<br />
    returnType:{{ returnType }}
  </span>
  <VueDatePicker
    ref="monthpicker"
    v-model="pickerDate"
    month-picker
    :locale="$route.params.langKey"
    :format="formatCH"
    :cancelText="$t('cancel')"
    :selectText="$t('choose')"
    :readonly="readonly"
    :clearable="clearable"
    :teleport="true"
    :alt-position="altPos"
    @update-month-year="dateUpdate"
  >
    <template #trigger>
      <v-text-field
        :name="name"
        :id="id"
        ref="monthpickerfield"
        :label="dateLabel"
        prepend-inner-icon="mdi-calendar"
        v-model="localCHDate"
        :rules="rules"
        :readonly="readonly"
        :clearable="clearable"
        clear-icon="mdi-close-circle"
        @change="change"
        @update="update"
        @keyup.enter="keyupEnter"
        @keyup.esc="keyupEsc"
        @click:clear="clearMessage"
        min-width="150"
      ></v-text-field>
    </template>
    <template #action-buttons></template>
  </VueDatePicker>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineEmits, toRefs, defineProps } from 'vue'
import { format, addMonths, addDays } from 'date-fns'
import { $rules } from '@/plugins/rules'

const props = defineProps({
  modelValue: { type: String, required: false, default: null },
  dateLabel: { type: String, required: false, default: '' },
  clearable: { type: Boolean, required: false, default: false },
  rules: {
    type: Array,
    required: false,
    default: () => [$rules.isCHMonth]
  },
  name: { type: String, required: false, default: 'dbmMonthPicker' },
  readonly: { type: Boolean, required: false, default: false },
  returnType: {
    type: String,
    default: 'firstOfMonth',
    validator: (value: string) => ['firstOfMonth', 'lastOfMonth', 'firstOfNextMonth'].includes(value)
  },
  id: { type: String, required: false, default: 'dbmmonthpicker' }
})

// you can NOT watch props.modelValue directly, nevertheless it affects computed props
// the props need to be declared as ref to become watchable
const modelValue = toRefs(props).modelValue

watch(
  modelValue,
  (newVal) => {
    internalValue.value = calcInternalDate(newVal)
  },
  { deep: true }
)

const emit = defineEmits(['change', 'keyup', 'keyup.enter', 'update:modelValue'])

let internalValue = ref(calcInternalDate(props.modelValue) as string | null)

let pickerDate = computed({
  get() {
    if (props.modelValue) {
      const dateToShow = calcInternalDate(props.modelValue)
      const [year, month] = dateToShow.split('-')
      return { year, month: parseInt(month) - 1 }
    } else {
      return { year: null, month: null }
    }
  },
  set({ month, year }) {
    const paddedMonth = `${month + 1}`.padStart(2, '0')
    const returnDate = calcReturnDate(`${year}-${paddedMonth}-01`)
    emit('update:modelValue', returnDate)
    emit('change', returnDate)
  }
})

let localCHDate = computed({
  get() {
    return formatCH(internalValue.value)
  },
  set(val) {
    if ($rules.isCHMonth(val) === true) {
      emit('update:modelValue', calcReturnDate(parseDate(val)))
    }
  }
})

const monthpickerfield = ref(null)

function update() {
  monthpickerfield.value.validate()
}

const monthpicker = ref(null)

function dateUpdate(yearMonthObj: { year: number; month: number }) {
  const month = internalValue.value ? internalValue.value.split('-')[1] : 0
  const paddedMonth = `${yearMonthObj.month + 1}`.padStart(2, '0')
  if (month != paddedMonth) {
    monthpicker.value.closeMenu()
  }
  pickerDate.value = yearMonthObj
}

function parseDate(date: string | undefined): string | null {
  if (!date) return null
  if (date.length > 5) {
    const [month, year] = date.split('.')
    return `${year}-${month.padStart(2, '0')}-01`
  }
  return null
}

function clearMessage() {
  emit('update:modelValue', null)
}

function formatCH(date: string | null): string | null {
  if (!date) return null
  return format(new Date(date), 'MM.yyyy')
}

function calcReturnDate(isoStringFirstOfMonth: string | null): string | null {
  if (!isoStringFirstOfMonth) return null
  switch (props.returnType) {
    case 'firstOfMonth':
      return format(new Date(isoStringFirstOfMonth), 'yyyy-MM-dd')
    case 'lastOfMonth':
      return format(addDays(addMonths(new Date(isoStringFirstOfMonth), 1), -1), 'yyyy-MM-dd')
    case 'firstOfNextMonth':
      return format(addMonths(new Date(isoStringFirstOfMonth), 1), 'yyyy-MM-dd')
    default:
      throw new Error('bad config: no default')
  }
}

function calcInternalDate(isoDateReturnDate: string | null): string {
  if (!isoDateReturnDate) return ''
  switch (props.returnType) {
    case 'firstOfMonth':
      return format(new Date(isoDateReturnDate), 'yyyy-MM-dd')
    case 'lastOfMonth':
      return format(addMonths(addDays(new Date(isoDateReturnDate), 1), -1), 'yyyy-MM-dd')
    case 'firstOfNextMonth':
      return format(addMonths(new Date(isoDateReturnDate), -1), 'yyyy-MM-dd')
    default:
      throw new Error('bad config: no default')
  }
}

function keyupEnter() {
  emit('keyup.enter', calcReturnDate(internalValue.value))
}

function keyupEsc() {
  if (props.modelValue && props.modelValue.length === 5) {
    localCHDate.value = format(new Date(props.modelValue), 'MM.yyyy')
  } else {
    localCHDate.value = ''
  }
}

function change() {
  emit('change', calcReturnDate(internalValue.value))
}

function altPos(element: HTMLElement) {
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  return { top: elemRect.bottom - bodyRect.top, left: elemRect.left - bodyRect.left }
}
</script>
